
interface DrawerState {
    isDrawerOpen:boolean,
}

export const useDrawer = defineStore('drawer', {
    state: (): DrawerState => {
        return {
            isDrawerOpen: false,
        }
    },
    actions: {
        openDrawer(){
            this.isDrawerOpen = true;
        },
        closeDrawer(){
            this.isDrawerOpen = false;
        }    
    }
})